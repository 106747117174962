import React, { useEffect, useRef } from "react";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { emptyJson, inValidJson } from "../../Util/config";

const JsonEditor = ({
  jsonSchema,
  mode = "code",
  onChangeJSON = () => {},
  setCanSubmit,
}) => {
  const containerRef = useRef(null);
  const jsonEditorRef = useRef(null);

  useEffect(() => {
    const options = {
      onChange: () => {
        if (jsonEditorRef.current) {
          jsonEditorRef.current.validate().then((errors) => {
            if (errors && errors.length > 0) {
              setCanSubmit && setCanSubmit(inValidJson);
            } else {
              if (mode === "view") {
                jsonEditorRef.current.expandAll();
              }
            }
          });
          try {
            const updatedJSON = jsonEditorRef.current?.get();
            if (JSON.stringify(updatedJSON) === JSON.stringify({})) {
              setCanSubmit(emptyJson);
            }
            onChangeJSON(updatedJSON);
          } catch (error) {
            onChangeJSON(null);
            setCanSubmit(emptyJson);
          }
        }
      },
      mode: mode,
      navigationBar: false,
    };
    if (!isNullOrEmpty(jsonSchema)) {
      jsonEditorRef.current = new JSONEditor(containerRef.current, options);
      jsonEditorRef.current.set(jsonSchema);
      mode === "view" && jsonEditorRef.current.expandAll();
    }
    return () => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonSchema, mode]);

  return <div className="jsoneditor-react-container" ref={containerRef} />;
};

export default JsonEditor;

