import { isNullOrEmpty } from "../../Util/commonUtility";
import React, { useCallback, useEffect, useMemo } from "react";
import { JSONEditor } from "@json-editor/json-editor";

const JsonForm = (props) => {
  const {
    formContainerRef,
    formEditorRef,
    jsonSchema,
    setFormValues,
    formValues,
    setCanSubmit,
    validationErrors,
    setValidationErrors,
  } = props;

  const memoizedFormOptions = useMemo(
    () => ({
      schema: jsonSchema,
      theme: "bootstrap4",
      iconlib: "fontawesome5",
      remove_button_labels: true,
      disable_edit_json: true,
      disable_properties: true,
      disable_collapse: true,
      disable_array_reorder: true,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      show_errors: "always",
    }),
    [jsonSchema]
  );
  const getRequiredFields = (schema) => {
    return schema.required || [];
  };
  const handleSetFormValues = useCallback(
    (newValues) => {
      setFormValues(newValues);
    },
    [setFormValues]
  );

  const handleSetValidationErrors = useCallback(
    (errors) => {
      setValidationErrors && setValidationErrors(errors);
    },
    [setValidationErrors]
  );
  useEffect(() => {
    if (!isNullOrEmpty(jsonSchema)) {
      JSONEditor.defaults.custom_validators.push((schema, value, path) => {
        const errors = [];
        const requiredFields = getRequiredFields(schema);
        requiredFields.forEach((field) => {
          if (
            value[field] === undefined ||
            value[field] === null ||
            value[field] === ""
          ) {
            errors.push({
              path: `${path}.${field}`,
              property: "required",
              message: `${field} is required`,
            });
          }
        });
        return errors;
      });

      formEditorRef.current = new JSONEditor(
        formContainerRef.current,
        memoizedFormOptions
      );

      formEditorRef.current.on("change", function () {
        const errors = formEditorRef.current.validate();
        handleSetValidationErrors(errors);
        const newFormValues = formEditorRef.current.getValue();
        const filteredValues = Object.keys(jsonSchema.properties).reduce(
          (acc, key) => {
            if (key in newFormValues) {
              acc[key] = newFormValues[key];
            } else {
              // If the key is not in newValues, use the default value from the schema
              acc[key] = jsonSchema.properties[key].default || null;
            }
            return acc;
          },
          {}
        );
        handleSetFormValues(filteredValues);
      });
    }
    return () => {
      formEditorRef.current?.destroy();
    };
  }, [
    formContainerRef,
    formEditorRef,
    handleSetFormValues,
    handleSetValidationErrors,
    jsonSchema,
    memoizedFormOptions,
  ]);

  useEffect(() => {
    if (validationErrors.length > 0) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [setCanSubmit, validationErrors]);

  useEffect(() => {
    if (!isNullOrEmpty(jsonSchema) && formEditorRef.current) {
      formEditorRef.current.schema = jsonSchema;
    }
  }, [formEditorRef, jsonSchema]);

  useEffect(() => {
    if (formEditorRef.current && formValues) {
      formEditorRef.current.on("ready", function () {
        const filteredValues = Object.keys(jsonSchema.properties).reduce(
          (acc, key) => {
            if (key in formValues) {
              acc[key] = formValues[key];
            } else {
              // If the key is not in newValues, use the default value from the schema
              acc[key] = jsonSchema.properties[key].default || null;
            }
            return acc;
          },
          {}
        );
        formEditorRef.current.setValue(filteredValues);
      });
    }
  }, [formEditorRef, handleSetFormValues, jsonSchema, formValues]);

  return (
    <>
      <div
        className="jsoneditor-form"
        id="jsoneditor-form"
        ref={formContainerRef}
      />
    </>
  );
};

export default JsonForm;

