import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  THEME_KEY,
  getLocalStorage,
  removeWhitespaceFromXAML,
  replaceContentWithPlaceholders,
  validateCodeSyntax,
} from "../../Util/commonUtility";
import { Option } from "antd/es/mentions";
import MonacoEditor from "../Common/monocoEditor";
import {
  ContentTypeOptions,
  ContentTypes,
  EditorDefaultOptions,
  LocalStorageEnums,
  maxNameCharacterLength,
  vsCodeTheme,
} from "../../Constant/Common";
import NotificationService from "../../Services/notification";
import ListContentPlaceholder from "./PlaceHolder/listContentPlaceholder";
import EditorPreview from "../ContentRollouts/ModalComponent/ModalComponent";
import { useParams } from "react-router-dom";

const { Sider } = Layout;

const AddContentTemplatesComponent = (props) => {
  const {
    backToListPage,
    onFinish,
    editContentTemplateData,
    languageItems,
    onPreviewXaml,
  } = props;
  const [formContentTemplate] = Form.useForm();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const initialPlaceholderValue =
    getLocalStorage(LocalStorageEnums.allPlaceholderData) || [];
  const [allPlaceHolderData, setAllPlaceHolderData] = useState(
    initialPlaceholderValue
  );
  const [selectedTemplateType, setSelectedTemplateType] = useState(false);
  const [replacedContent, setReplacedContent] = useState(false);
  const [templateSchemaContent, setTemplateSchemaContent] = useState(false);
  const [isEditorCodeValid, setIsEditorCodeValid] = useState(false);
  const [isEditorCodeSyntaxError, setIsEditorCodeSyntaxError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isTemplateSchemaNonEmpty = templateSchemaContent?.length > 0;
  const isPreviewVisible =
    isEditorCodeSyntaxError && isTemplateSchemaNonEmpty && isEditorCodeValid;

  const isDarkMode = getLocalStorage(THEME_KEY) || false;

  const handleEditorChange = (value) => {
    setTemplateSchemaContent(value);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleOpen = async () => {
    if (selectedTemplateType === ContentTypes.XAML) {
      onPreviewXaml(removeWhitespaceFromXAML(replacedContent));
    } else {
      setIsModalVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleTemplateTypeChange = (value) => {
    setSelectedTemplateType(value);
  };

  const handleTemplateValidation = (
    templateValidation,
    isEditorCodeValid,
    formattedData
  ) => {
    if (!templateValidation.isValid) {
      NotificationService.error(templateValidation.message);
      return;
    }
    if (!isEditorCodeValid) {
      NotificationService.error(
        `The placeholder used in the template does not exist in the placeholders table`
      );
      return;
    }
    onFinish(formattedData);
  };

  const handleSubmit = (values) => {
    if (!replacedContent || !selectedTemplateType) return;
    const selectedLanguageObjects = values.languages?.map((code) => {
      const lang = languageItems.find((lang) => lang.code === code);
      return lang;
    });
    const templateValidation = validateCodeSyntax(
      replacedContent,
      selectedTemplateType
    );
    const placeholderPattern = /{{(.*?)}}/g;
    const extractedTemplatePlaceholder = [
      ...templateSchemaContent.matchAll(placeholderPattern),
    ].map((match) => match[1]);
    const placeHolderUsedInTemplateSchema = allPlaceHolderData.filter((item) =>
      extractedTemplatePlaceholder.includes(item.name)
    );

    const formattedData = {
      ...values,
      templateSchema: templateSchemaContent,
      templateType: selectedTemplateType,
      languages: selectedLanguageObjects,
      templatePlaceholder: placeHolderUsedInTemplateSchema || [],
    };

    handleTemplateValidation(
      templateValidation,
      isEditorCodeValid,
      formattedData
    );
  };

  useEffect(() => {
    if (editContentTemplateData) {
      const {
        templateType,
        templateSchema,
        templatePlaceholder,
        languages,
        ...restData
      } = editContentTemplateData;
      setSelectedTemplateType(templateType);
      setTemplateSchemaContent(templateSchema);
      setAllPlaceHolderData(templatePlaceholder);
      formContentTemplate.setFieldsValue({
        ...restData,
        templateSchema: templateSchema,
        templatePlaceholder: templatePlaceholder || [],
        languages: languages.map((item) => {
          return item?.code;
        }),
      });
    }
  }, [editContentTemplateData, formContentTemplate]);

  useEffect(() => {
    if (templateSchemaContent || allPlaceHolderData) {
      const { replacedContent, isValid } = replaceContentWithPlaceholders(
        templateSchemaContent,
        allPlaceHolderData
      );
      const { isValid: isCodeValid } = validateCodeSyntax(
        replacedContent,
        selectedTemplateType
      );
      setReplacedContent(replacedContent);
      setIsEditorCodeValid(isValid);
      setIsEditorCodeSyntaxError(isCodeValid);
    }
  }, [templateSchemaContent, allPlaceHolderData, selectedTemplateType]);

  return (
    <>
      <Form
        initialValues={editContentTemplateData}
        form={formContentTemplate}
        className="form-container"
        name="add-product"
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
      >
        {/* Top row with updated by/updated at tags */}
        <Row gutter={[16, 16]}>
          <Col span={24} prefixCls="info-text-container">
            {editContentTemplateData && id && (
              <Space direction="horizontal">
                {editContentTemplateData.updatedBy && (
                  <>
                    Updated By :
                    <Tag className="tag">
                      {editContentTemplateData.updatedBy}
                    </Tag>
                  </>
                )}
                {editContentTemplateData.updatedAt && (
                  <>
                    Updated Date :
                    <Tag className="tag">
                      {new Date(
                        editContentTemplateData.updatedAt
                      ).toDateString()}
                    </Tag>
                  </>
                )}
              </Space>
            )}
            <Space direction="vertical" prefixCls="content-info">
              <Tag>
                <InfoCircleOutlined className="mr-1" />
                {`To use a placeholder within the content template, enclose
                  the placeholder name in double curly braces. For example, to
                  use the 'New_Placeholder', format it as {{ New_Placeholder }}.`}
              </Tag>
              <Tag>
                <InfoCircleOutlined className="mr-1" />
                {`Any placeholders that are not utilized in the template will be lost upon form submission!!`}
              </Tag>
            </Space>
          </Col>
        </Row>

        <Layout className="min-height-card-container content-template-container">
          {/* Sider for additional options */}
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapsed}
            width={300}
            className="sider-container"
          >
            <Card
              className="card-container deco-card-padding min-height-card-container"
              title={collapsed ? false : "Content Template"}
              extra={
                <Button
                  id="content-sider-close-btn"
                  className="sider-close-btn"
                  type="ghost"
                  onClick={toggleCollapsed}
                >
                  {collapsed ? <RightOutlined /> : <LeftOutlined />}
                </Button>
              }
            >
              {collapsed ? "Expand to add/edit content template data" : null}
              <Form.Item hidden={true} name="id" id="ContentTemplateId">
                <Input />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                hidden={collapsed}
                rules={[
                  { required: true, message: "Please input template name!" },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                ]}
              >
                <Input
                  id="ContentTemplateName"
                  className="build-rollout-max-width"
                />
              </Form.Item>

              <Form.Item
                label="Template Type"
                name="templateType"
                hidden={collapsed}
                rules={[
                  { required: true, message: "Please select a template type!" },
                ]}
              >
                <Select
                  defaultValue={selectedTemplateType}
                  placeholder="Select Content Type"
                  onChange={handleTemplateTypeChange}
                >
                  {ContentTypeOptions.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                hidden={collapsed}
                rules={[
                  {
                    required: true,
                    message: "Please input template description!",
                  },
                ]}
              >
                <TextArea
                  id="ContentTemplateDescription"
                  className="build-rollout-max-width"
                />
              </Form.Item>

              <Form.Item label="Languages" hidden={collapsed} name="languages">
                <Select
                  allowClear
                  mode="multiple"
                  placeholder="Select Languages"
                  tagRender={({ label }) => <Tag>{label}</Tag>}
                  maxTagCount={2}
                  maxTagPlaceholder={(omittedValues) =>
                    `+${omittedValues.length}`
                  }
                >
                  {languageItems?.map((lang) => (
                    <Option key={lang.code} value={lang.code}>
                      {lang.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Enabled?"
                name="isEnable"
                initialValue={false}
                hidden={collapsed}
                valuePropName="checked"
              >
                <Switch id="ContentTemplateSwitch" />
              </Form.Item>
            </Card>
          </Sider>

          {/* Main form editor */}
          <Card className="card-container child-container card-style-none template-max-width">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Card className="card-container deco-card-padding min-height-card">
                  <div className="editor-container">
                    <div className="editor">
                      <div className="justify-button-and-center">
                        <h1>Template Editor</h1>
                      </div>
                      <EditorPreview
                        visible={isModalVisible}
                        onClose={handleClose}
                        content={replacedContent}
                        placeholder={allPlaceHolderData}
                        language={selectedTemplateType}
                      />
                      <MonacoEditor
                        height="100%"
                        language={selectedTemplateType}
                        theme={
                          isDarkMode ? vsCodeTheme.dark : vsCodeTheme.light
                        }
                        value={templateSchemaContent}
                        allPlaceHolderData={allPlaceHolderData}
                        onChange={handleEditorChange}
                        options={EditorDefaultOptions}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={12}>
                <Card className="card-container deco-card-padding min-height-card">
                  <div className="placeholder-table">
                    <ListContentPlaceholder
                      allPlaceHolderData={allPlaceHolderData}
                      setAllPlaceHolderData={setAllPlaceHolderData}
                      templateSchema={templateSchemaContent}
                    />
                  </div>
                </Card>
              </Col>
            </Row>

            <Row justify="end" className="button-container">
              <Button
                id="ContentTemplateBackButton"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={backToListPage}
                className="back-button"
              >
                Back
              </Button>
              <div className="template-content-button-group">
                <Button
                  disabled={!isPreviewVisible}
                  type="primary"
                  onClick={handleOpen}
                >
                  <EyeOutlined />
                  Preview {selectedTemplateType}
                </Button>
                <Button
                  className="submit-button"
                  id="ContentTemplateSubmitButton"
                  type="primary"
                  htmlType="submit"
                >
                  {editContentTemplateData ? "Save" : "Submit"}
                </Button>
              </div>
            </Row>
          </Card>
        </Layout>
      </Form>
    </>
  );
};

export default AddContentTemplatesComponent;

